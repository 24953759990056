* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

}

body {
  background: rgb(222, 222, 222, 1);
  color: rgb(8, 8, 8);
  font-family: 'poppins', sans-serif;
}

.App {
  height: 100vh;
  min-height: 100vh;
  display: flex;
}

.sideBar {
  flex: 2;
  border-right: 7px solid rgb(255, 255, 255);
}

.main {
  flex: 9;

}

.logo {
  margin-right: 1rem;
  height: 50px;
  width: 50px;
}

.brand {
  font-size: 2rem;
  font-weight: bolder;
  margin-left: 10px;
}

.upperSide {
  padding: 2.5rem;
  border-bottom: 7px solid rgb(255, 255, 255);
  height: 80%;
}

.upperSideTop {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.addBtn {
  height: 2rem;
  padding-right: 1rem;
}

.midBtn {
  background: #061ea7;
  border: none;
  color: white;
  padding: 1.5rem;
  font-size: 1.5rem;
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
}

.query {
  display: flex;
  align-items: center;
  background: white;
  color: rgb(10, 0, 0);
  padding: 1.5rem;
  font-size: 1.4rem;
  width: 20rem;
  margin: 1rem auto;
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
}

.lowerSide {
  padding: 1rem 2rem;
}

.listItems {
  margin: 0.75rem;
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  margin-top: 3rem;
  font-weight: bold;
}

.listItemsImg {
  margin: 1rem;
  padding-right: 1rem;
}

.listItems:nth-child(1)>.listItemsImg {
  margin-right: 0.5rem;
}

.listItems:nth-child(3)>.listItemsImg {
  margin-right: 0.75rem;
}

.anchor {
  text-decoration: none;
  color: black;
}

.main {
  min-height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 10rem;
  overflow-y: auto;
}

.chats {
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100%;
  max-width: 70rem;
  height: calc(100vh - 17rem);
}

.chat {
  margin: 1rem;
  padding: 2rem 3rem;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  text-align: justify;
}

.chatimg {
  object-fit: cover;
  width: 3.5rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
}

.txt {
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
}

.bot {
  background: rgb(255, 255, 255);
  width: fit-content;
  border-radius: 0.5rem;
}

.user {
  background: rgba(78, 101, 213, 0.822);
  width: fit-content;
  border-radius: 0.5rem;
  color: white;
}

.user>p {
  color: aliceblue;
}



.chatFooter {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inp {
  margin-top: 1rem;
  padding: 0.5rem;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  width: 68rem;
}

.inputPlaceholder {
  color: white
}

.inp>input {
  width: calc(100% - 3rem);
  outline: none;
  padding: 1.25rem;
  color: rgb(0, 0, 0);
}

.inp>input,
.send {
  background: transparent;
  border: none;
}

.textToSpeech {
  padding: 0.25rem;
  border-radius: 0.375rem;
  color: rgb(0, 0, 0);
  border: none;
  margin: 0.3rem;
  background: transparent;
}

.user>.textToSpeech {
  color: rgb(255, 255, 255);
}

.playBtn {
  padding: 0.25rem;
  border-radius: 0.375rem;
  color: rgb(0, 0, 0);
  margin: 0.3rem;
  border: 1px solid rgb(0, 0, 0);
  background: transparent;
}

.loading-message {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.dots {
  display: inline-block;
  font-size: 5rem; /* Adjust the size of the dots */
  animation: dotsAnimation 1.5s infinite; /* Adjust the duration of the animation */
}

@keyframes dotsAnimation {
  0%, 20% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px); /* Adjust the vertical movement */
  }
  80%, 100% {
    transform: translateY(0);
  }
}

/* Mobile Styles */
@media screen and (max-width: 850px) {
  .App {
    flex-direction: column;
  }

  .sideBar {
    flex: 1;
    border-right: none;
  }

  .lowerSide {
    display: none;
  }

  .main {
    flex: 1;
    margin: 2rem 0;
    overflow: hidden;
  }

  .upperSide {
    padding: 1.5rem;
    border-bottom: 5px solid rgb(255, 255, 255);
  }

  .upperSideTop {
    flex-direction: column;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .midBtn {
    width: 100%;
    margin-bottom: 1rem;
  }

  .query {
    width: 100%;
  }

  .listItems {
    flex-direction: column;
    text-align: center;
  }

  .listItemsImg {
    margin: 0.5rem 0;
  }

  .main {
    margin: 2rem 1rem;
  }

  .txt {
    font-size: 10px;
  }

  .inp {
    width: 20rem;
  }

  .chats {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1180px) {
  .inp {
    width: 35rem;
  }
}

/* Tablet Styles */
@media screen and (min-width: 769px) {
  .sideBar {
    flex: 2;
    border-right: 7px solid rgb(255, 255, 255);
  }

  .main {
    flex: 9;
  }

  /* Additional tablet styles if needed */
}